import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Header from "../components/header"
import ReactPlayer from "react-player"




export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___index], order: DESC }) {
      nodes {
        id
        frontmatter {
          title
          index
          link
          runtime
        }
      }
    }
  }
`



const BlogIndex = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes
  
  const [mixUrl, setMixUrl] = useState("");


  return (
    <Layout>
      {/* <Seo /> */}
      <Header />

      <div className="min-h-screen pt-2 pb-36 md:pt-4">

        <div className="w-full grid grid-cols-2 md:grid-rows-1 md:grid-cols-6 my-8 gap-4 md:gap-8 text-sm"> 

          <div id="archive" className="col-span-8 text-3xl mb-2 ">
            <h1 className=" font-bold">WAX ASSEMBLY</h1>
            <h2 className=" font-light">A MIX ARCHIVE</h2>
          </div>
        </div>
        <div className="w-full grid grid-cols-2 md:grid-rows-1 md:grid-cols-6 mt-8 mb-12 gap-4 md:gap-8 text-sm">
          <div className="hidden md:block col-span-1 ">
            <p className="text-gray">
              index
            </p>
          </div>
          <div className="hidden md:block col-span-2 ">
            <p className="text-gray">Show</p>
          </div>
          
          <div className="md:hidden ">
            <p className="text-gray">
              index/show
            </p>
          </div>

          <div className="hidden md:block col-span-2 ">
            <p className="text-gray">runtime</p>
          </div>

          <div className="hidden md:block col-span-1 ">
            <p className="text-gray">link</p>
          </div>
          <div className=" md:hidden">
            <p className="text-gray">
              runtime/link
            </p>
          </div>
        </div>

      
        <div className="border-t-1 block border-gray border-t border-opacity-25 -mx-4 md:-mx-8 w-screen">
        </div>

        {posts.map(post => {
          return (
            <div key={post.id} className="w-full grid grid-cols-2 md:grid-rows-1 md:grid-cols-6 mt-8 mb-12 gap-4 md:gap-8 text-sm"> 
              
              <div className="col-span-1 hidden sm:block col-start-1 row-start-2 md:row-start-1">
                
                <p>{post.frontmatter.index}</p>
                
              </div>
              <div className="col-span-2 sm:col-span-1 md:col-span-2 row-start-1  md:col-start-2">
                <p className=" font-bold">{post.frontmatter.title}</p>
              </div>

              <div className="col-span-1 md:col-start-4 md:col-span-2">
                <p>{post.frontmatter.runtime}</p>
              </div>

              <div className="col-span-1 row-start-2 md:row-start-1 md:col-start-6">
                <button onClick={() => setMixUrl(post.frontmatter.link)} className="align-bottom inline-block uppercase">Play</button><span className="align-bottom inline-block"> — </span><a href={post.frontmatter.link}> <span className="align-bottom inline-block">link</span></a>
              </div>
            
            </div>
          )
        })}
        <div className="fixed -bottom-8 w-screen -mx-4 md:-mx-8 mt-20">
          <ReactPlayer width="100%" height="150px" url={mixUrl}></ReactPlayer>
        </div>
      </div>
     

    </Layout>

  )
}

export default BlogIndex


