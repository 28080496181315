/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Helmet } from "react-helmet"


 
 const Header = () => {

    

    return (
        <div className="w-full -mx-4 md:-mx-8">

            <Helmet>
                <script src="/js/patch.js" type="text/javascript" async/>

                <script src="/js/patchinit.js" type="text/javascript" defer/>

                <link rel="stylesheet" href="https://use.typekit.net/fdx5yzp.css"></link>

            </Helmet>

            <canvas className="  lg:block" id="glcanvas" width="100vw" height="100vh"></canvas>               
            <div className=" lg:block text-center w-full text-3xl bottom-40 absolute font-bold">
                <div className=" inline-block">
                    <a className=" underline" href="#archive">LISTEN</a>
                </div>
            </div>
        </div>
    )
 }
 
 export default Header
 